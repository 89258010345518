import axios from 'axios'
import { API_ROUTES } from '../routes'

export const deleteAccountingData = async (
  customerId: string,
  year: string
) => {
  const from = new Date(Number(year), 0, 1).toISOString()
  const to = new Date(Number(year), 11, 31).toISOString()

  return await axios.delete(
    API_ROUTES.CUSTOMER + customerId + '/accounting?from=' + from + '&to=' + to
  )
}
